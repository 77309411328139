import { template as template_e92753a7c76648769c413e7afda4178c } from "@ember/template-compiler";
const SidebarSectionMessage = template_e92753a7c76648769c413e7afda4178c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
