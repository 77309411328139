import { template as template_1ce4008b7d244e7ab70cc0d36ffa56f5 } from "@ember/template-compiler";
const WelcomeHeader = template_1ce4008b7d244e7ab70cc0d36ffa56f5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
