import { template as template_1f3d4bafbb7e440aa5070a806f34d8d3 } from "@ember/template-compiler";
import { Input } from "@ember/component";
import concatClass from "discourse/helpers/concat-class";
import { i18n } from "discourse-i18n";
const PreferenceCheckbox = template_1f3d4bafbb7e440aa5070a806f34d8d3(`
  <div class={{concatClass "controls" @class}} ...attributes>
    <label class="checkbox-label">
      <Input @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />

      {{#if @labelCount}}
        {{i18n @labelKey count=@labelCount}}
      {{else}}
        {{i18n @labelKey}}
      {{/if}}
    </label>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PreferenceCheckbox;
