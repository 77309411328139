import { template as template_b7daff62b20e4e03b0574f6eff31a7dd } from "@ember/template-compiler";
const FKText = template_b7daff62b20e4e03b0574f6eff31a7dd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
